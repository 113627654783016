import React, { useEffect, useState } from 'react';
import Web3 from 'web3';


import {
    ButtonProps, FormControl, FormHelperText, FormHelperTextProps, Input, InputLabel
} from '@mui/material';


import LoadingButton from '@mui/lab/LoadingButton';
import './index.css';
import Footer from '../../components/footer';



import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import useToken from '../../utils/useToken';
import IssuesGrid from '../../components/issues';
import EmailTextField from '../../components/EmailTextField';
import IssueLinkTextField from '../../components/IssueLinkTextField';
import IssueRewardTextField from '../../components/IssueRewardTextField';

const host_prefix: string = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000';
declare var window: any

const ColorLoadingButton = styled(LoadingButton)
    <ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#6e3f3f"),
        borderRadius: 2,
        marginTop: 20,
        justifyContent: 'center',
        backgroundColor: "#6e3f3f",
        '&:hover': {
            backgroundColor: "#110829",
        },
        "&:disabled": {
            backgroundColor: "#7c7c84",
        },
    }));


const EmailFormHelperText = styled(FormHelperText)
    <FormHelperTextProps>(() => ({
        color: 'white',
    })
    );

const ProjectIssues = () => {
    var [issueLink, setIssueLink] = useState("");
    var [reward, setReward] = useState("");
    var [submitted, setSubmitted] = useState<boolean>(false);
    var [message, setMessage] = useState("");
    var [error, setError] = useState("");


    const [searchParams, setSearchParams] = useSearchParams();
    //console.log(searchParams);
    const project_id = searchParams.get('project');
    //console.log(`Project Issues has received params: ${JSON.stringify(searchParams)}`);
    const { token, setToken } = useToken();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        const issuebody = {
            "github_url": issueLink,
            "reward": Number(reward),
            "project_id": project_id
        }
        console.log('Submitting the issue', issuebody);
        event.preventDefault();
        try {
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            axios.post(`${host_prefix}/api/issues`, issuebody, options).then((res) => {
                if (res.data.success) {
                    console.log('Successfully    the issue');
                    setSubmitted(true);
                } else {
                    setSubmitted(false);
                }
                setMessage(res.data.message);
                setSubmitted(res.data.success);

            })
        } catch (error) {
            console.log('Waitlist form submission error: ' + error);
        }
    }

    const handleEthCall = async () => {

        if (window.ethereum) {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
        }
        const accounts = await window.web3.ethereum.request({ method: 'eth_requestAccounts' });
        window.web3.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: accounts[0],
                        to: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
                        value: '0x29a2241af62c0000',
                        gasPrice: '0x09184e72a000',
                        gas: '0x2710',
                    },
                ],
            })
            .then((txHash: any) => console.log(txHash))
            .catch((error: any) => console.error(error));

    }
    return (
        <React.Fragment>

            <body className="bg-image" >
                <img src={require('./../../images/logo_cropped.png')} className="logo" />
                <br />
                <form onSubmit={handleSubmit}>
                    <FormControl>{!submitted ? (
                        <div>
                            <div>
                                <IssueLinkTextField value={issueLink} onChange={setIssueLink} /><br />
                                <EmailFormHelperText color="white">*Copy paste the url for your issue, and our process will pick up the details.*</EmailFormHelperText>
                                <IssueRewardTextField value={reward.toString()} onChange={setReward} /><br />

                            </div>
                            <ColorLoadingButton
                                size="small"
                                type="submit"
                                loadingIndicator="Loading..."
                                variant="outlined"
                            >
                                Submit
                            </ColorLoadingButton>
                        </div>
                    ) : (
                        <div>
                            <div>Alright, its submitted, time to give us eth.</div>
                            <ColorLoadingButton
                                size='small'
                                type='submit'
                                loadingIndicator="Loading..."
                                variant='outlined'
                                onClick={handleEthCall}>

                            </ColorLoadingButton>
                        </div>
                    )}
                    </FormControl>
                </form>
                <Footer />
            </body>
        </React.Fragment>
    )

}

export default ProjectIssues;