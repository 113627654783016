import { Card, CardActionArea, CardMedia, CardContent, Typography, Button, CardActions, makeStyles, Grid, Paper } from "@mui/material";

// const useGridStyles = makeStyles(({ theme: Theme }) => ({
//     root: {
//         [breakpoints.up('md')]: {
//             justifyContent: 'center',
//         },
//     },
// }));

// const useStyles = makeStyles(() => ({
//     actionArea: {
//         borderRadius: 16,
//         transition: '0.2s',
//         '&:hover': {
//             transform: 'scale(1.1)',
//         },
//     },
//     card: ({ color }) => ({
//         minWidth: 256,
//         borderRadius: 16,
//         boxShadow: 'none',
//         '&:hover': {
//             boxShadow: `0 6px 12px 0 ${Color(color)
//                 .rotate(-12)
//                 .darken(0.2)
//                 .fade(0.5)}`,
//         },
//     }),
//     content: ({ color }) => {
//         return {
//             backgroundColor: color,
//             padding: '1rem 1.5rem 1.5rem',
//         };
//     },
//     title: {
//         fontFamily: 'Keania One',
//         fontSize: '2rem',
//         color: '#fff',
//         textTransform: 'uppercase',
//     },
//     subtitle: {
//         fontFamily: 'Montserrat',
//         color: '#fff',
//         opacity: 0.87,
//         marginTop: '2rem',
//         fontWeight: 500,
//         fontSize: 14,
//     },
// }));



const OrganizationsGrid = (props: {
    organizations: [{
        "ethereumSetupComplete": boolean,
        "completeData": [],
        "_id": string,
        "status": number,
        "name": string,
        "email": string,
        "username": string,
        "github_url": string,
        "createdAt": string,
        "updatedAt": string,
        "__v": number,
        "description": string,
        "avatar_url": string,
    }]
}) => {
    const organizations = props.organizations;
    return (
        <Grid container spacing={3} wrap={'nowrap'}>
            {
                organizations.map(organization => (
                    <Grid item key={organization._id}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                image={organization.avatar_url}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {organization.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {organization.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"
                                    href={`/projects?organization=${organization._id}`}>Projects</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}


            {/* 
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image="../../images/dev.png"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles, with over 6,000
                        species, ranging across all continents except Antarctica
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Share</Button>
                    <Button size="small">Learn More</Button>
                </CardActions>
            </Card> */}
        </Grid>

    )
}

export default OrganizationsGrid;