import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        return tokenString !== null ? JSON.parse(tokenString) : null;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken: { token: string }) => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    }

    return {
        setToken: saveToken,
        token
    }
}