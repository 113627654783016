import { Card, CardActionArea, CardMedia, CardContent, Typography, Button, CardActions, makeStyles, Grid, Paper } from "@mui/material";

const ProjectsGrid = (props: {
    projects: [{
        "_id": string,
        "name": string,
        "github_url": string,
        "tags": string,
        "organization_id": string,
        "createdAt": string,
        "updatedAt": string,
        "__v": number,
        "description": string
    }]
}) => {
    //console.log(props.projects);
    const projects = props.projects;
    return (
        <Grid container spacing={3} wrap={'nowrap'}>
            {
                projects.map(project => (
                    <Grid item key={project._id}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {project.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {project.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"
                                    href={`/issues?project=${project._id}`}>Issues
                                </Button>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
        </Grid>

    )
}

export default ProjectsGrid;