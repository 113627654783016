// import './index.css';
// import { Routes, Route } from 'react-router-dom';
// import Home from '../Home';

import React from "react";
import { Fragment } from "react";
import Router from "./routes";


const App = () => (
  <Fragment>
    <Router />
  </Fragment>
)


export default App;