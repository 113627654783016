import { CssBaseline, AppBar, Toolbar, Typography, Box, Button, useScrollTrigger, createTheme, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import Pdf from '../../static/Dungeoneers.pdf';

//import AdbIcon from '@mui/icons-material/Adb';


interface Props {
    children: React.ReactElement;
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#110829',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});



const navItems = ['About Us', 'For Projects', 'For Developers'];

function ElevationScroll(props: Props) {
    const [scrollTarget, setScrollTarget] = useState<Node | Window | undefined>()
    const trigger = useScrollTrigger({ target: scrollTarget });

    const { children } = props;
    return React.cloneElement(children, {
        elevation: trigger ? 3 : 0
    });
}



const Header = () => {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ElevationScroll {...React.createElement}>
                <AppBar
                    color='primary'
                    position="sticky"
                >
                    <Toolbar>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: "left" }}
                        >
                            Dungeoneers
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button href={Pdf} key='White Paper' sx={{ color: '#fff' }}>
                                White Paper
                            </Button>
                            <Button onClick={Pdf} key='For Projects' sx={{ color: '#fff' }}>
                                For Projects
                            </Button>
                            <Button key='For Developers' sx={{ color: '#fff' }}>
                                For Developers
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </ThemeProvider>
    )
}

export default Header;
