import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, Divider, Grid, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import SampleOrganizations from '../organizations';
import SampleResumes from '../resumes';
import Carousel from 'react-material-ui-carousel';
import Footer from '../../footer';
import SampleTalents from '../talent_acquisition';

const introductions = [
    {
        name: "Bug Bounty that reward with crypto assets!!",
        description: "Organizations can create and reward Eth tokens for bug bounties for their open source projects. ",
        subdescription: "Just create github issues and sign them up for tracking on our platform. Our smart contracts are connected to the Webhooks and reward the developers when their PR gets approved and merged.",
        image: "",
        display: <SampleOrganizations />


    },
    {
        name: "Create Immutable Resume on the blockchain",
        description: "The achievements of the developers are stored on public immutable blockchain.",
        subdescription: "Developers just share their public address with hiring teams and our platforms will collect the information as a immutable, and publically validated resume and share them with the organizations the developers wishes to share them with.",
        display: <SampleResumes />,
    },
    {
        name: "Talent Acquisition",
        description: "Find the best candidate by through our blockchain backed resumes.",
        subdescription: "Our platform matches your requirements to individuals and teams with the best matching skillset. The digital resumes are immutable, which means each data point in it is verified and backed by our public ledger.",
        display: <SampleTalents />,
    }
    // ,
    // {
    //     name: "Benefits and Bonuses",
    //     description: "Provide benefits to the team by smart contract programmed bonuses and milestone rewards.",
    //     subdescription: "Encode benefits to smart contracts by adding rules(such as anniversary acheivements at the company, completion of target number of bounties etc) to our blockchain. These rules are appended to our smart contract, which means they can be trusted, automated and verifed.",
    //     display: <SampleOrganizations />,
    // }
];

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
    spacing: 10,

});

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',


};

const introHeadingStyles = {
    bgcolor: '#110829',
    borderColor: 'text.primary',
    marginTop: 2,

};

export default function Introductions() {
    return (
        <ThemeProvider theme={theme}>

            <Carousel>
                {introductions.map((introduction) => {
                    const { name, description, subdescription, display } = introduction;
                    return (
                        <Grid item>
                            <Card >
                                <CardContent sx={{ ...introHeadingStyles }}>
                                    <Box sx={{ borderRadius: '6px' }} >
                                        <Typography variant="h5" component="div" color="common.white">
                                            {name}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </CardContent>
                                <CardContent>
                                    <Box sx={{ borderRadius: '6px' }} >
                                        <Typography variant="body1" color="secondary" >
                                            {description}
                                        </Typography>
                                        <Typography variant="body2" color="primary" >
                                            {subdescription}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                {display}
                            </Card>
                        </Grid>
                    )
                })}
            </Carousel >
        </ThemeProvider >
    );

}