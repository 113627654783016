import React, { useState } from 'react';
import Web3 from 'web3';



import {
    Box,
    Button,
    Container,
    createTheme,
    CssBaseline,
    Divider,
    Grid,
    Paper,
    ThemeProvider,
    Typography,
} from '@mui/material';

import Footer from '../../components/footer';
import Header from '../../components/header';
import './index.css';

import Organizations from '../../components/walkthroughs/organizations';
import Projects from '../../components/walkthroughs/projects';
import Introductions from '../../components/walkthroughs/introductions';
const host_prefix: string = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000';



const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
});



const Welcome = () => {
    return (
        <div>
            <CssBaseline />
            <Header />
            <Container>
                <Introductions />
            </Container>
            <Footer />
        </div >

    )
}

export default Welcome;