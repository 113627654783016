import React, { useEffect, useState } from 'react';
import Web3 from 'web3';


import {
    ButtonProps
} from '@mui/material';


import LoadingButton from '@mui/lab/LoadingButton';
import './index.css';
import Footer from '../../components/footer';



import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import useToken from '../../utils/useToken';
import OrganizationsGrid from '../../components/organizations';

const host_prefix: string = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000';


const ColorLoadingButton = styled(LoadingButton)
    <ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#6e3f3f"),
        borderRadius: 2,
        marginTop: 100,
        justifyContent: 'center',
        backgroundColor: "#6e3f3f",
        '&:hover': {
            backgroundColor: "#110829",
        },
        "&:disabled": {
            backgroundColor: "#7c7c84",
        },
    }));



const Organizations = () => {
    const { token, setToken } = useToken();
    let [organizations, setOrganizations] = useState<[{
        "ethereumSetupComplete": boolean,
        "completeData": [],
        "_id": string,
        "status": number,
        "name": string,
        "email": string,
        "username": string,
        "github_url": string,
        "createdAt": string,
        "updatedAt": string,
        "__v": number,
        "description": string,
        "avatar_url": string,
    }]>();
    let [avaliable, setAvailable] = useState(false);
    // let [searchParams, setSearchParams] = useSearchParams();



    useEffect(() => {
        //fetchOrganizations(token);
        async function fetchOrganizations() {
            console.log('Making request to organizations endpoint');
            try {
                const options = {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
                axios.get(`${host_prefix}/api/organizations`, options).then(async (res) => {
                    //console.log(res)
                    if (res.data && token) {

                        if (res.data.length > 0) {
                            //console.log(res.data)

                            setOrganizations(res.data);
                        }
                    }
                })
            } catch (error) {
                console.log('Welcome redirect error: ' + error);
            }
        }

        fetchOrganizations();
    }, []);


    if (organizations) {
        //console.log(organizations)
        return (
            <React.Fragment>
                <body className="bg-image" >
                    <br /><br /><br /><br /><br />
                    <OrganizationsGrid organizations={organizations} />
                    <Footer />
                </body>
            </React.Fragment>
        )
    } else {

        return (
            <React.Fragment>
                <body className="bg-image" >
                    <img src={require('./../../images/logo_cropped.png')} className="logo" />
                    <br />
                    <ColorLoadingButton
                        size="small"
                        type="submit"
                        loadingIndicator="Loading..."
                        variant="outlined"
                    >
                        Connect with Github
                    </ColorLoadingButton>
                    <Footer />
                </body>
            </React.Fragment>
        )
    }
}

export default Organizations;