import { Card, CardContent, Typography, Button, CardActions, Grid } from "@mui/material";

const IssuesGrid = (props: {
    issues: [{
        "_id": string,
        "organization_id": string,
        "project_id": string,
        "github_url": string,
        "reward": number,
        "title": string,
        "createdAt": string,
        "updatedAt": string
    }]
}) => {
    const issues = props.issues;
    return (
        <Grid container spacing={3} wrap={'nowrap'}>{
            issues.map(issue => (
                <Grid item key={issue._id}>
                    <Card sx={{ maxWidth: 345 }}>

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {issue.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Rewards: {issue.reward}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small"
                                href={issue.github_url}>github link</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>

    )
}

export default IssuesGrid;