import * as React from 'react';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { createTheme, Divider, Grid, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';

const resumes = [
    {
        name: "John Doe",
        languages: [{ name: "Python", projects: 18 }, { name: "Javascript", projects: 32 }, { name: "Java", projects: 3 }],
        bounties: 3,
        rewarded: "3.42",
        avatar_url: "https://github.com/images/error/octocat_happy.gif",
        address: '0xBB94e0913d92ef39fb47501E9e4E36d9F28044C1',
        solutions: [
            { url: "https://github.com/3ekauq/tasks_repo/issues/1", reward: '1.10' },
            { url: "https://github.com/3ekauq/tasks_repo/issues/2", reward: '2.09' },
            { url: "https://github.com/3ekauq/tasks_repo/issues/3", reward: '0.23' }
        ],
        status: 'freelancer',
        location: 'Menlo Park, CA'
    },
    {
        name: "Jason Bird",
        languages: [{ name: "Python", projects: 8 }, { name: "Javascript", projects: 3 }, { name: "Java", projects: 31 }],
        bounties: 4,
        rewarded: "2.21",
        avatar_url: "https://github.com/images/error/octocat_happy.gif",
        address: '0xAB94e1913d82ef29fb47801E9e4E36d9F28044C1',
        solutions: [
            { url: "https://github.com/3ekauq/tasks_repo/issues/4", reward: '0.80' },
            { url: "https://github.com/3ekauq/tasks_repo/issues/5", reward: '1.09' },
            { url: "https://github.com/3ekauq/tasks_repo/issues/6", reward: '0.12' },
            { url: "https://github.com/3ekauq/tasks_repo/issues/7", reward: '0.20' }
        ],
        status: 'open to work',
        location: 'Boston, MA'
    }

];

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
});

const resumeOverviewDesciptionStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.secondary',
    m: 1,
    border: 1,
};

const resumeDescriptionStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.secondary',

    border: 1,
    color: '#110829'
};

export default function SampleResumes() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="center" spacing={6}>
                {resumes.map((resume, index) => {
                    const { name, languages, bounties, rewarded, avatar_url, address, solutions, status, location } = resume;
                    return (
                        <Grid item>
                            <Card sx={{ ...resumeDescriptionStyles, maxWidth: 400, height: 400, margin: 4 }}>
                                <CardContent>

                                    <Typography variant="h5">
                                        {name}
                                    </Typography>
                                    <Divider color="white" />
                                    <br />
                                    <Typography variant="body1">
                                        Rewards Won: {rewarded}
                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        Availablility: {status}
                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        Languages(Total Projects)</Typography>
                                    <Typography variant="body2">
                                        {languages.map((language) => (
                                            <Typography variant="body2">
                                                {language["name"]} ({bounties})
                                            </Typography>
                                        ))}

                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        Bounties {bounties}
                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        Address:
                                        <br />

                                    </Typography>
                                    <Typography variant="overline">
                                        {address}
                                    </Typography>


                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid >
        </ThemeProvider>
    );
}
