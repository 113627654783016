
import Pdf from '../../static/Dungeoneers.pdf';
import { createTheme, Grid, Link, ThemeProvider, Typography } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
});

const footerHeaderStyles = {
    borderColor: 'text.primary',
    color: '#110829bf'
};

const footerCopyrightStyles = {
    borderColor: 'text.primary',
    color: '#1108299f',
}

const Footer = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container
                justifyContent="center"
                marginTop={10}
                columnSpacing={32}
            >
                <Grid item gridColumn={1}>

                    <Typography sx={{ ...footerHeaderStyles }} variant='h6' >
                        About Us
                    </Typography>
                    <Link color='#1108299f' href={Pdf}>White Paper</Link><br />
                    <Link color='#1108299f' href="https://github.com/RohanJahagirdar">Team</Link><br />

                </Grid>

                <Grid item gridColumn={3}>
                    <Typography sx={{ ...footerHeaderStyles }} variant='h6'>
                        Terms
                    </Typography>

                    <Link color='#1108299f' href="/terms" >Terms of Use</Link><br />
                    <Link color='#1108299f' href="/policy" >Privacy Policy</Link><br />
                    <Link color='#1108299f' href="https://www.dungeoneers.dev/disclaimer">Disclaimer</Link><br />

                </Grid>
            </Grid>
            <br />
            <Typography sx={{ ...footerCopyrightStyles }}>
                Copyright © 2022 Dungeoneers.dev. All rights reserved.
            </Typography>
            <Link color='#1108292f' href="/projectissues" >.</Link>
            <Link color='#1108292f' href="/welcome" >.</Link><a>     </a>
            <Link color='#1108292f' href="/organizations" >--</Link>
        </ThemeProvider >
    )
}

export default Footer;