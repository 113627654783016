import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Home';
import UserIssues from '../pages/userissues';
import Organizations from '../pages/organizations';
import Projects from '../pages/projects';
import Welcome from '../pages/welcome';
import ProjectIssues from '../pages/projectissues';



const Router = () => (
    <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/welcome'} element={<Welcome />} />
        <Route path={'/organizations'} element={<Organizations />} />
        <Route path={'/projects'} element={<Projects />} />
        <Route path={'/issues'} element={<UserIssues />} />
        <Route path={'/projectissues'} element={<ProjectIssues />} />
    </Routes>
)


export default Router;