import * as React from 'react';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { createTheme, Divider, Grid, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';

const organizations = [
    {
        name: "Dungeoneers",
        description: "Dungeoneers is blockchain-based employment, benefits, and bug reward platform for professionals and organizations to showcase experiences and technical expertise via open-source issue bug tracking and optimized hiring solutions.",
        projects: 3,
        available_rewards: "121 Eth",
        rewarded: "33 Eth",
        bounties: 10
    },
    {
        name: "Google",
        description: "Google LLC is an American multinational technology company that specializes in Internet-related services and products, which include online advertising technologies, a search engine, cloud computing, software, and hardware solutions.",
        projects: 21,
        available_rewards: "141 Eth",
        rewarded: "121 Eth",
        bounties: 211
    }

];

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
});

const orgOverviewDesciptionStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    m: 1,
    border: 1,
};

const orgDescriptionStyles = {
    bgcolor: '#110829',
    borderColor: 'text.primary',
    m: 1,
    border: 1,
};

export default function SampleOrganizations() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="center" spacing={6}>
                {organizations.map((organization, index) => {
                    const { name, description, projects, available_rewards, rewarded, bounties } = organization;
                    return (
                        <Grid item>
                            <Card sx={{ ...orgDescriptionStyles, maxWidth: 340, height: 400, margin: 4 }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" color="common.white">
                                        {name}
                                    </Typography>
                                    <Divider color="white" />
                                    <br color="white" />
                                    <Typography variant="body2" color="common.white" >
                                        {description}
                                    </Typography>
                                    <br />
                                    <Box sx={{ ...orgOverviewDesciptionStyles, borderRadius: '6px' }} >

                                        <Typography variant="body2" color="text.secondary">
                                            Projects: {projects}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Available Rewards: {available_rewards}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Rewarded {rewarded}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Bounties {bounties}
                                        </Typography>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid >
        </ThemeProvider>
    );


    // <Grid container spacing={1}>
    //     {cards.map((cards, index) => {
    //         const { image, name, description } = cards;
    //         return (
    //             <Grid item>
    //                 <Card key={index} className={classes.card}>
    //                     <CardMedia className={classes.media} image={image} />
    //                     <CardContent className={classes.content}>
    //                         <Typography
    //                             className={"MuiTypography--heading"}
    //                             variant={"h6"}
    //                             gutterBottom
    //                         >
    //                             {name}
    //                         </Typography>
    //                         <Typography
    //                             className={"MuiTypography--subheading"}
    //                             variant={"caption"}
    //                         >
    //                             {description}
    //                         </Typography>
    //                         <Divider className={classes.divider} light />
    //                         {faces.map(face => (
    //                             <Avatar className={classes.avatar} key={face} src={face} />
    //                         ))}
    //                     </CardContent>
    //                 </Card>
    //             </Grid>
    //         );
    //     })}
    // </Grid>

}