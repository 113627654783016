import React from "react";
import Welcome from "../welcome";



const Landing = () => {

    const waitlistState = {
        email: "",
        stored: false,
        submitted: false,
        message: ""

    }

    return (
        <Welcome />
    )
}

export default Landing;