import * as React from 'react';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { colors, createTheme, Divider, Grid, Icon, MenuItem, Select, SelectChangeEvent, Stack, TextField, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';


const talents = {
    search: {
        availibility: "Freelancer",
        location: "US (West Coast)",
        languages: [{ name: "Javascript", experience: "Expert" }, { name: "Python", experience: "Senior" }, { name: "Java", experience: "Basic" }]
    },
    results: [
        {
            name: "John Doe",
            languages: [{ name: "Python", projects: 18 }, { name: "Javascript", projects: 32 }, { name: "Java", projects: 3 }],
            bounties: 3,
            rewarded: "3.42",
            avatar_url: "https://github.com/images/error/octocat_happy.gif",
            address: '0xBB94e0913d92ef39fb47501E9e4E36d9F28044C1',
            solutions: [
                { url: "https://github.com/3ekauq/tasks_repo/issues/1", reward: '1.10' },
                { url: "https://github.com/3ekauq/tasks_repo/issues/2", reward: '2.09' },
                { url: "https://github.com/3ekauq/tasks_repo/issues/3", reward: '0.23' }
            ],
            status: 'freelancer',
            location: 'Menlo Park, CA'
        }
    ],
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#110829'
        }
    },
});

const searchOverviewDesciptionStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.secondary',


};

const acquisitionDescriptionStyles = {
    bgcolor: '#110829',
    borderColor: 'text.primary',
    color: '#110829'
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function SampleTalents() {
    const [experience, setExperience] = useState(["Expert", "Senior", "Basic"]);
    const { search, results } = talents;
    const handleClick = (index: number, value: string): void => {
        console.log("now _", index, value)
        experience[index] = value;
        setExperience(experience);
        console.log("then _", experience)
        // setExperiences(current => { query, ...current, ...response);

        // setExperience()
        //setExperience(index: event.target.value);
    };


    return (
        <ThemeProvider theme={theme}>
            <Grid container columns={12}
                alignItems="center"
            >
                <Grid item
                    xs={6}
                    textAlign="center"
                    direction="column"
                    justifyContent="center"
                    padding={2}
                >
                    <Stack
                        component="form"
                        spacing={2}
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-read-only-input"
                            label="Availibility"
                            size="small"
                            defaultValue={search.availibility}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="Location"
                            size="small"
                            defaultValue={search.location}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <Stack
                            component="form"
                            spacing={2}
                            autoComplete="off"
                            divider={<Divider orientation="horizontal" flexItem />}

                        >
                            <Typography variant="body1">
                                Languages
                            </Typography>

                            {search.languages.map((language, index) => {
                                console.log(index, experience[index])
                                return (
                                    <div>
                                        <TextField
                                            id="outlined-read-only-input"
                                            size="small"
                                            defaultValue={language.name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />

                                        <Select
                                            labelId={index.toString()}
                                            value={experience[index]}
                                            label="Experience"
                                            size='small'
                                            disabled={true}
                                            onChange={(e) => handleClick(index, e.target.value.toString())}

                                        >
                                            <MenuItem value="Expert">Expert</MenuItem>
                                            <MenuItem value="Senior">Senior</MenuItem>
                                            <MenuItem value="Basic">Basic</MenuItem>
                                        </Select>

                                    </div>

                                )
                            })}

                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={6}>
                    {results.map((result, index) => {
                        const { name, languages, bounties, rewarded, avatar_url, address, solutions, status, location } = result;
                        return (
                            <Grid item>
                                <Card sx={{ ...acquisitionDescriptionStyles, maxWidth: 400, height: 400, margin: 4 }}>
                                    <CardContent>

                                        <Typography variant="h5" color="common.white">
                                            {name}
                                        </Typography>
                                        <Divider color="white" />
                                        <br />
                                        <Typography variant="body1" color="common.white">
                                            Rewards Won: {rewarded} Eth
                                        </Typography>
                                        <br />
                                        <Typography variant="body1" color="common.white">
                                            Availablility: {status}
                                        </Typography>
                                        <br />
                                        <Typography variant="body1" color="common.white">
                                            Languages(Total Projects)</Typography>
                                        <Typography variant="body2" color="common.white">
                                            {languages.map((language) => (
                                                <Typography variant="body2">
                                                    {language["name"]} ({language["projects"]})
                                                </Typography>
                                            ))}
                                        </Typography>
                                        <br />
                                        <Typography variant="body1" color="common.white">
                                            Bounties {bounties}
                                        </Typography>
                                        <br />
                                        <Typography variant="body1" color="common.white">
                                            Address:
                                            <br />

                                        </Typography>
                                        <Typography variant="overline" color="common.white">
                                            {address}
                                        </Typography>


                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}