import { createTheme, TextField, ThemeProvider } from "@mui/material";
import React from "react";

interface IssueLinkTextFieldProps {
    value: string;
    onChange: (val: string) => void;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: '#11cb5f',
        },
    },
});


const IssueLinkTextField = ({ value, onChange }: IssueLinkTextFieldProps) => {
    return (
        <ThemeProvider theme={theme}>

            <TextField value={value}
                size="small"
                color='primary'
                label="Github Link"
                fullWidth
                sx={{
                    marginLeft: 1,
                    marginTop: 10,


                    padding: 0,
                    container: {
                        display: 'flex',

                    },
                    input: {
                        color: "#6e3f3f",
                        background: "white"
                    },
                    '& label.Mui-focused': {
                        color: "white",
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: "white",
                    },
                    '& input:valid + fieldset': {
                        borderColor: 'green',
                        borderWidth: 2,
                    },
                    '& input:invalid + fieldset': {
                        borderColor: 'red',
                        borderWidth: 2,
                    },
                    '& input:valid:focus + fieldset': {
                        borderLeftWidth: 6,
                        padding: '4px !important', // override inline-style
                    },

                }}
                onChange={({ target: { value } }) => onChange(value)}
            />
        </ThemeProvider>)
}

export default IssueLinkTextField;